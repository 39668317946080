const headlineCardData = [
  {
    imgSquare: require('../images/archive/2025_whm/Amplify_500x500.jpg'),
    img: require('../images/archive/2025_whm/Amplify_900x1260.jpg'),
    name: 'WHM25_Amplify',
    title: 'WHM25_Amplify',
  },
  {
    imgSquare: require('../images/archive/2025_whm/Inspire_500x500.jpg'),
    img: require('../images/archive/2025_whm/Inspire_900x1260.jpg'),
    name: 'WHM25_Inspire',
    title: 'WHM25_Inspire',
  },
  {
    imgSquare: require('../images/archive/2025_whm/Unite_500x500.jpg'),
    img: require('../images/archive/2025_whm/Unite_900x1260.jpg'),
    name: 'WHM25_Unite',
    title: 'WHM25_Unite',
  },
]

export default headlineCardData
